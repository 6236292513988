const path = require('path')
const Timestamp = new Date().getTime()
module.exports = {
  publicPath: process.env.NODE_ENV === 'production' ? '/webmanage/' : '/webmanage/',
  outputDir: 'webmanage',
  configureWebpack: { // webpack 配置
    output: { // 输出重构 打包编译后的 文件名称 【模块名称.版本号.时间戳】
      filename: `static/js/[name].${Timestamp}.js`,
      chunkFilename: `static/js/[name].${Timestamp}.js`
    }
  },

  devServer: {
    // host: '0.0.0.0',
    // port: 8080, //启动端口
    open: true, // 启动后是否自动打开网页
    proxy: {
      '/api': { // 这里最好有一个/
        // target: 'https://aicity.visionmiracle.cn',
        target: 'https://tcity.lebopark.com', // 后台接口域名开发环境
        // target: 'https://fatcity.lebopark.com/', // 后台接口域名测试环境
        // target: 'http://188.128.0.140:6018', // 后台接口域名测试环境
        ws: false, // 如果要代理websockets，配置这个参数
        secure: false, // 如果是https接口，需要配置这个参数
        changeOrigin: true, // 是否跨域
        pathRewrite: {
          '^/api': '/api'
        }
      },
      '/txt2img-images': { // 这里最好有一个/
        target: 'https://tcity.lebopark.com', // 后台接口域名开发环境
        // target: 'http://uatcity.lebopark.com',
        // target: 'http://aa.fatcity.lebopark.com/',//后台接口域名测试环境
        ws: false, // 如果要代理websockets，配置这个参数
        secure: false, // 如果是https接口，需要配置这个参数
        changeOrigin: true // 是否跨域
        // pathRewrite: {
        //     '^/miracleChat': '/'
        // }
      }
    }
  },
  chainWebpack (config) {
    config.externals({ './cptable': 'var cptable' })
  },
  pluginOptions: {
    'style-resources-loader': {
      preProcessor: 'less',
      patterns: [
        // 这个是加上自己的路径,不能使用(如下:alias)中配置的别名路径
        path.resolve(__dirname, './src/theme/style.less')
      ]
    }
  },
  // devServer: {
  //     overlay: {
  //         warnings: false, //不显示警告
  //         errors: false //不显示错误
  //     }
  // },
  lintOnSave: false // 关闭eslint检查
}
